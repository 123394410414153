import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface CustomInputProps {
  placeholder: string;
  onChange?: any;
  className?: any;
  value?: any;
  helperText?: any;
  disabled?: any;
  error?: any;
  label: string;
  showEyeIcon?: boolean;
  required?: any;
  forgetPassword?: boolean;
  onForgetPasswordClick?: any;
}

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  className,
  showEyeIcon,
  placeholder,
  onChange,
  value,
  forgetPassword,
  helperText,
  disabled,
  error,
  required,
  onForgetPasswordClick,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const inputType = showPassword ? "text" : "password";
  return (
    <div className={`py-2 ${className}`}>
      <InputLabel
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        htmlFor="outlined-basic"
        required={required}
        // error={error}
        disabled={disabled}
      >
        {label}
        {forgetPassword && (
          <span
            className="absolute right-0 text-blue-500 cursor-pointer"
            onClick={onForgetPasswordClick}
          >
            Forget password?
          </span>
        )}
      </InputLabel>
      <div className="relative">
        <OutlinedInput
          id="outlined-basic"
          placeholder={placeholder}
          onChange={onChange}
          fullWidth
          error={error}
          value={value}
          type={showEyeIcon ? inputType : "text"}
          endAdornment={
            showEyeIcon && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }
          className={`!border h-10 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10`}
          disabled={disabled}
        />
      </div>
      <p className="text-xs text-red-500 absolute">{helperText}</p>
    </div>
  );
};

export default CustomInput;
