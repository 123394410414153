import { useMemo } from "react";
import { logo } from "../../assets/icons";
import { useLocation } from "react-router";
import NavBarItem from "./NavBarItem";
import { useDispatch } from "react-redux";
import { setLogin } from "../../redux/features/auth/authSlice";
import { Page } from "../../routes/config";
import { useNavigate } from "react-router-dom";
import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/outline";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pathname = location?.pathname;
  const allRoutes = useMemo(
    () => [
      {
        label: "Users",
        active: pathname === "/",
        href: "/",
      },
      {
        label: "Applications",
        active: pathname === "/applications",
        href: "/applications",
      },
    ],
    [pathname]
  );
  return (
    <>
      <header className="top-0 sticky z-50">
        <nav className="flex items-center justify-between flex-wrap bg-blue-gray-900 p-4 shadow-xl">
          <div className="flex items-center flex-shrink-0 text-white mr-6 gap-x-2">
            <img src={logo} className="w-10 h-10" alt="" />
            <span className="font-semibold text-xl tracking-tight">
              Authenticator
            </span>
          </div>
          <div className="block sm:hidden">
            <button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div className="w-full block flex-grow sm:flex sm:items-center sm:w-auto">
            <div className="text-sm flex gap-x-4 sm:flex-grow">
              {allRoutes?.filter(Boolean)?.map((item: any) => (
                <NavBarItem key={item.label} {...item} />
              ))}
            </div>
            <div>
              <a
                href="/"
                className="cursor-pointer flex gap-x-1 items-center text-sm px-2 py-2  rounded text-gray-300  hover:text-white  mt-4 sm:mt-0"
                onClick={() => {
                  dispatch(setLogin(false));
                  navigate(Page.LOGIN);
                }}
              >
                <ArrowRightStartOnRectangleIcon
                  strokeWidth={2}
                  className="h-5 w-5"
                />{" "}
                Log out
              </a>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
