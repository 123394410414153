import React from "react";

interface CustomModalProps {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  buttonType?: "button" | "submit" | "reset" | undefined;
  showModal?: boolean;
  onClose?: () => void;
  saveText?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  children,
  title,
  className,
  showModal = true,
  onClose,
  saveText,
  buttonType = "button",
}) => {
  return (
    <>
      {showModal ? (
        <div
          id="hs-scroll-inside-body-modal"
          className={`backdrop-blur-sm bg-black/30  w-full h-full fixed flex top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto items-center justify-center  `}
        >
          <div
            className={` m-3 justify-center flex mx-3 w-full md:h-fit h-[calc(100%-1.5rem)] ${className} `}
          >
            <div className="max-h-full w-3/5 overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
              <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                <h3 className="font-bold text-gray-800 dark:text-white">
                  {title}
                </h3>
                <button
                  onClick={onClose}
                  type="button"
                  className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  data-hs-overlay="#hs-scroll-inside-body-modal"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </div>
              <div className="p-4 overflow-y-auto">
                <div className="space-y-4 ">{children}</div>
              </div>
              <div className="flex justify-end items-center">
                <div className=" flex gap-x-2 px-4 py-2">
                  <button
                    onClick={onClose}
                    type="button"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    data-hs-overlay="#hs-scroll-inside-body-modal"
                  >
                    Close
                  </button>
                  <button
                    type={buttonType}
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  >
                    {saveText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomModal;
