import { BaseUrls } from "../types";

const baseUrls: BaseUrls | any = {
  local: "http://localhost:8080/api/v1/",
  dev: "https://authstag.innobitsystems.com/api/v1",
  qa: "",
  load: "",
  prod: "https://auth.innobitsystems.com/api/v1",
};

export const ENV: string = process.env.REACT_APP_RUNNING_ENV
  ? process.env.REACT_APP_RUNNING_ENV
  : "dev";

export const baseURL = baseUrls[ENV];

export enum apiRoutes {
  GET_ALL_USERS = "/users",
  CREATE_USER = "/user",
  GET_ALL_APPLICATIONS = "/applications",
  CREATE_APPLICATION = "/application",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgetPassword",
  RESETPASSWORD = "/resetPassword ",
}
