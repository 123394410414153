import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { apiSlice } from "./services/apiSlice";
import { authApiSlice } from "./services/authApiSlice";
import { appApiSlice } from "./services/appApiSlice";
import { userApiSlice } from "./services/userApiSlice";
import authReducer from "../redux/features/auth/authSlice";
import alertReducer from "./features/alert/alertSlice";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["api", "alert"],
};

export const rootReducers = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [apiSlice.reducerPath]: apiSlice.reducer,
  [authApiSlice.reducerPath]: authApiSlice.reducer,
  [appApiSlice.reducerPath]: appApiSlice.reducer,
  [userApiSlice.reducerPath]: userApiSlice.reducer,
  auth: authReducer,
  alert: alertReducer,


});

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware:any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
        apiSlice.middleware,
        authApiSlice.middleware,
    ]),
  devTools: true,
});

setupListeners(store.dispatch);
