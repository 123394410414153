import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { UserPlusIcon, PencilIcon } from "@heroicons/react/24/solid";
import { Card, CardHeader, Typography, Button } from "@material-tailwind/react";

import CustomModal from "../../common/Modal";
import React, { useEffect, useState } from "react";
import {
  useCreateUserMutation,
  useGetAllUsersQuery,
  useUpdateUserMutation,
} from "../../../redux/services/userApiSlice";
import { Table } from "../../common/Table";
import { useForm, Controller } from "react-hook-form";
import CustomInput from "../../common/Input";
import { useGetAllAppsQuery } from "../../../redux/services/appApiSlice";
import {
  Chip,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from "@mui/material";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { useDispatch } from "react-redux";
import { RegExpressions } from "../../../constants/enums";
import SelectInput from "../../common/MultiSelectInput";
import { GridPaginationModel } from "@mui/x-data-grid";
import { CustomToolTip } from "../../common/toolTip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Users = () => {
  const theme = useTheme();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [allUsersCount, setAllUsersCount] = useState<any>(0);
  const [appName, setAppName] = React.useState<string[]>([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tableHeight, setTableHeight] = useState(0);
  const [searchValue, setSearchValue] = useState<any>("");
  const [addUserCheck, setAddUserCheck] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<any>([]);
  const [status, setStatus] = useState<any>();
  const [gender, setGender] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [editUserCheck, setEditUserCheck] = useState(false);
  const [isActive, setIsActive] = useState<any>("active");

  //API Integration
  const allAppsData: any = useGetAllAppsQuery({});
  const [addUser, addUserData] = useCreateUserMutation();
  const [editUser, editUserData] = useUpdateUserMutation();
  const { data, isSuccess, isError, refetch } = useGetAllUsersQuery({
    limit: paginationModel?.pageSize,
    page: paginationModel?.page + 1,
    search: searchValue,
    isActive:
      isActive === "active" ? true : isActive === "All" ? undefined : false,
  });

  const { handleSubmit, control, setValue, reset } = useForm({
    mode: "onChange" || "onSubmit",

    defaultValues: {
      firstName: null,
      middleName: null,
      lastName: null,
      password: null,
      email: null,
      mobileNumber: null,
      employeeId: null,
      appName: null,
      status: null || "",
      gender: null,
    },
  });
  const dispatch = useDispatch();
  let columns = [
    {
      field: "employeeId",
      headerName: "Employee Id",
      minWidth: 200,
      align: "left",
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      flex: 1,
      renderCell: ({ row }: any) => (
        <CustomToolTip title={row?.employeeId} placement="top">
          <p className="textSlice">{row?.employeeId}</p>
        </CustomToolTip>
      ),
    },
    {
      field: "FullName",
      headerName: "Full Name",
      minWidth: 200,
      align: "left",
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      flex: 1,
      renderCell: ({ row }: any) => (
        <CustomToolTip
          title={`${row?.firstName} ${row?.middleName} ${row?.lastName}`}
          placement="top"
        >
          <p className="textSlice">
            {row?.firstName} {row?.middleName} {row?.lastName}
          </p>
        </CustomToolTip>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 400,
      align: "left",
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      flex: 1,
      renderCell: ({ row }: any) => (
        <CustomToolTip title={row?.email} placement="top">
          <p className="textSlice">{row?.email}</p>
        </CustomToolTip>
      ),
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      minWidth: 200,
      align: "left",
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      flex: 1,
      renderCell: ({ row }: any) => (
        <CustomToolTip title={row?.mobileNumber} placement="top">
          <p className="textSlice">{row?.mobileNumber}</p>
        </CustomToolTip>
      ),
    },
    {
      field: "appName",
      headerName: "Application",
      minWidth: 200,
      align: "left",
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      flex: 1,
      renderCell: ({ row }: any) =>
        row.applications.map((x: any) => {
          const app = allAppsData?.data?.result.find((app: any) => {
            return app?.appId === x;
          });
          return (
            <Chip
              className="mr-1.5"
              key={app?.appId}
              variant="outlined"
              size="small"
              label={app?.name}
            />
          );
        }),
    },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 200,
      align: "left",
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      flex: 1,
      renderCell: ({ row }: any) => (
        <Chip
          // variant="ghost"
          size="small"
          label={row?.isActive ? "Active" : "InActive"}
          color={row?.isActive ? "success" : "error"}
        />
      ),
    },
    {
      field: "action",
      sortable: false,
      align: "left",
      headerAlign: "left",
      headerClassName: "stickyColumn",
      headerName: "",
      cellClassName: "cell-padding",
      width: 2,
      renderCell: ({ row }: any) => (
        // onClick={(event: any) => handleMenuClick(event, params?.row?.id)}
        <div
          role="button"
          onClick={() => {
            setSelectedUser(row);
            setEditUserCheck(true);
          }}
        >
          <PencilIcon className="h-5 w-5" />
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (isSuccess && data && data?.result) {
      setAllUsersCount(data?.result?.totalcount);

      setAllUsers(
        data?.result?.userData.map((row: any, index: any) => ({
          id: index,
          ...row,
        }))
      );
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "No User Found",
        })
      );
    }
  }, [isError]);

  const handleClose = () => {
    reset();
    if (addUserCheck) {
      setAddUserCheck(false);
      reset();
      setGender(null);
      setStatus(null);
      setAppName([]);
    } else {
      setEditUserCheck(false);
      reset();
      setGender(null);
      setStatus(null);
      setAppName([]);
    }
  };

  const onSubmit = (data: any) => {
    let appId = allAppsData?.data?.result.map((item: any) => {
      let filterId = appName.find((x: any) => {
        return x === item.appId;
      });
      if (filterId) {
        return { appId: item.appId, status: true };
      } else {
        return { appId: item.appId, status: false };
      }
    });

    const requestBody = {
      firstName: data?.firstName,
      middleName: data?.middleName,
      lastName: data?.lastName,
      password: editUserCheck ? undefined : data?.password,
      email: data?.email,
      mobileNumber: data?.mobileNumber,
      employeeId: data?.employeeId,
      isActive: status === "active" ? true : false,
      gender: gender,
      appId: appId,
    };
    if (editUserCheck) {
      editUser({ ...requestBody, userId: selectedUser?.userId });
    } else {
      addUser(requestBody);
    }
  };
  function getStyles(name: string, appName: string[], theme: Theme) {
    return {
      fontWeight:
        appName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleChange = (event: SelectChangeEvent<typeof appName>) => {
    const {
      target: { value },
    } = event;
    setAppName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  useEffect(() => {
    if (addUserData?.isSuccess) {
      refetch();
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "User added successfully",
        })
      );
      setAddUserCheck(false);
      reset();
      setGender(null);
      setStatus(null);
      setAppName([]);
    }
  }, [addUserData?.isSuccess]);
  useEffect(() => {
    if (addUserData?.isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: addUserData?.error?.data?.error?.message,
        })
      );
    }
  }, [addUserData?.isError]);
  useEffect(() => {
    if (editUserData?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "User Updated successfully",
        })
      );
      setEditUserCheck(false);
      reset();
      setGender(null);
      setStatus(null);
      setAppName([]);
      refetch();
    }
  }, [editUserData?.isSuccess]);
  useEffect(() => {
    if (editUserData?.isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: editUserData?.error?.data?.error?.message,
        })
      );
    }
  }, [editUserData?.isError]);
  useEffect(() => {
    if (editUserCheck) {
      setValue("firstName", selectedUser?.firstName);
      setValue("middleName", selectedUser?.middleName);
      setValue("lastName", selectedUser?.lastName);
      setValue("email", selectedUser?.email);
      setValue("mobileNumber", selectedUser?.mobileNumber);
      setValue("employeeId", selectedUser?.employeeId);
      setValue("gender", selectedUser?.gender);
      setValue("status", selectedUser?.isActive ? "active" : "inactive");
      setValue("appName", selectedUser?.applications);
      setGender(selectedUser?.gender);
      setStatus(selectedUser?.isActive ? "active" : "inactive");
      setAppName(selectedUser?.applications);
    }
  }, [editUserCheck]);

  const handleSearch = (event: any) => {
    let { value } = event.target;
    setSearchValue(value);
  };
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
  };
  const calHeaight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".user");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeaight();
    const resizeObserver = new ResizeObserver(() => {
      calHeaight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  return (
    <>
      {/* <AlertCustomStyles /> */}
      <Card className="h-full w-full" placeholder={undefined}>
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none sticky top-[72px] z-[80] mx-0 m-0 "
          placeholder={undefined}
        >
          <div className="mb-2 flex sm:items-center flex-col sm:flex-row sm:justify-between gap-8 p-4 ">
            <div>
              <Typography
                variant="h5"
                color="blue-gray"
                placeholder={undefined}
              >
                Users ({allUsersCount})
              </Typography>
              <Typography
                color="gray"
                className="mt-1 font-normal"
                placeholder={undefined}
              >
                See information about all users
              </Typography>
            </div>
            <div className="flex shrink-0 sm:w-[60%] justify-end  flex-col gap-2 sm:flex-row">
              <div className="sm:w-[60%]">
                {/* <Input
                  // label="Search"
                  onChange={handleSearch}
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  crossOrigin={undefined}
                  placeholder="Search by name & employee Id"
                  className={`!border bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10`}
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[100px]" }}
                /> */}
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={"text"}
                  className={`!border bg-white text-gray-900 h-10 w-full shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10`}
                  placeholder="Search by name & employee Id"
                  onChange={handleSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <MagnifyingGlassIcon className="h-5 w-5" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="sm:w-[20%]">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="h-10 text-black-500 w-full"
                  value={isActive}
                  displayEmpty
                  // label="Status"
                  onChange={(e) => setIsActive(e.target.value)}
                >
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>Inactive</MenuItem>
                  <MenuItem value={"All"}>All</MenuItem>
                </Select>
              </div>

              <div className="flex shrink-0 flex-col gap-2 sm:flex-row sm:w-[20%]">
                <Button
                  className="flex items-center gap-3 w-full justify-center"
                  size="sm"
                  placeholder={undefined}
                  onClick={() => setAddUserCheck(true)}
                >
                  <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Add user
                </Button>
              </div>
            </div>
          </div>
          <hr />

          {/* <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <div className="w-full">
             
            </div>
          </div> */}
        </CardHeader>
        {/* <CardBody
          className="p-0 no-scrollbar overflow-scroll px-2.5"
          placeholder={undefined}
        > */}
        <div className="user">
          <Table
            columns={columns}
            rows={allUsers}
            rowCount={allUsersCount}
            isPagination={true}
            onPaginationModelChange={handlePaginationModelChange}
            pagination={paginationModel}
            tableHeight={tableHeight}
          ></Table>
        </div>
      </Card>

      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomModal
          buttonType="submit"
          saveText={editUserCheck ? "Save Changes" : "Save"}
          onClose={handleClose}
          showModal={addUserCheck || editUserCheck}
          title={editUserCheck ? "Edit User" : "Add User"}
          className={" expandAnimationBottom"}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4">
            <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-3 gap-x-4 ">
              <div>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <CustomInput
                      label="First Name"
                      {...field}
                      value={field.value}
                      placeholder="Enter First name"
                      error={Boolean(error)}
                      helperText={error?.message ? error?.message : " "}
                      onChange={field.onChange}
                      //   inputRef={(e: any) => {
                      //     ref(e);
                      //     inputRef.current = e;
                      //   }}
                      required={true}
                      // autoFocus={true}
                    />
                  )}
                  name="firstName"
                  control={control}
                  rules={{
                    required: "Please enter first name",
                    pattern: {
                      value: RegExpressions?.FirstName,
                      message: "Please enter valid first name.",
                    },
                  }}
                />
              </div>

              <div>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <CustomInput
                      label="Middle Name"
                      {...field}
                      value={field.value}
                      placeholder="Enter Middle Name"
                      error={Boolean(error)}
                      helperText={error?.message ? error?.message : " "}
                      onChange={field.onChange}
                      //   inputRef={(e: any) => {
                      //     ref(e);
                      //     inputRef.current = e;
                      //   }}

                      // autoFocus={true}
                    />
                  )}
                  name="middleName"
                  control={control}
                  rules={{
                    //   required: "Please enter email or mobile number",
                    pattern: {
                      value: RegExpressions?.MiddleName,
                      message: "Please enter valid middle name",
                    },
                  }}
                />
              </div>
              <div>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <CustomInput
                      label="Last Name"
                      {...field}
                      value={field.value}
                      placeholder="Enter Last Name"
                      error={Boolean(error)}
                      helperText={error?.message ? error?.message : " "}
                      onChange={field.onChange}
                      //   inputRef={(e: any) => {
                      //     ref(e);
                      //     inputRef.current = e;
                      //   }}

                      // autoFocus={true}
                    />
                  )}
                  name="lastName"
                  control={control}
                  rules={{
                    // required: "Please enter last name",
                    pattern: {
                      value: RegExpressions?.FirstName,
                      message: "Please enter valid last name.",
                    },
                  }}
                />
              </div>
            </div>

            <div>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <CustomInput
                    label="Employee Id"
                    {...field}
                    value={field.value}
                    placeholder="Enter Employee Id"
                    disabled={editUserCheck ? true : false}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    //   inputRef={(e: any) => {
                    //     ref(e);
                    //     inputRef.current = e;
                    //   }}
                    required={true}
                    // autoFocus={true}
                  />
                )}
                name="employeeId"
                control={control}
                rules={{
                  required: "Please enter employee Id",
                  pattern: {
                    value: RegExpressions?.EmployeeId,
                    message: "Please enter valid employee id ",
                  },
                }}
              />
            </div>
            <div>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <CustomInput
                    label="Email"
                    {...field}
                    value={field.value}
                    placeholder="Enter Email Address"
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    //   inputRef={(e: any) => {
                    //     ref(e);
                    //     inputRef.current = e;
                    //   }}
                    required={true}
                    // autoFocus={true}
                  />
                )}
                name="email"
                control={control}
                rules={{
                  required: "Please enter email address",
                  pattern: {
                    value: RegExpressions?.Email,
                    message: "Please enter valid email",
                  },
                }}
              />
            </div>
            {!editUserCheck && (
              <div>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <CustomInput
                      label="Password"
                      {...field}
                      value={editUserCheck ? "********" : field.value}
                      placeholder="Enter Password"
                      showEyeIcon={!editUserCheck ? true : false}
                      error={Boolean(error)}
                      required={true}
                      helperText={error?.message ? error?.message : " "}
                      onChange={field.onChange}
                      disabled={editUserCheck ? true : false}
                    />
                  )}
                  name="password"
                  control={control}
                  rules={{
                    required: editUserCheck ? false : "Please enter password",
                    pattern: {
                      value: RegExpressions?.Password,
                      message:
                        "Password should have atleast 8 characters and should contain atleast one uppercase letter, one lowercase letter, one number and one special character.",
                    },
                  }}
                />
              </div>
            )}

            <div>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <CustomInput
                    label="Mobile Number"
                    {...field}
                    value={field.value}
                    placeholder="Enter Mobile Number"
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    //   inputRef={(e: any) => {
                    //     ref(e);
                    //     inputRef.current = e;
                    //   }}
                    required={true}
                    // autoFocus={true}
                  />
                )}
                name="mobileNumber"
                control={control}
                rules={{
                  required: "Please enter mobile number",
                  pattern: {
                    value: RegExpressions?.MobileNumber,
                    message: "Please enter valid mobile number",
                  },
                }}
              />
            </div>
            <div className={` w-full h-full`}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <>
                    <InputLabel
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      htmlFor="demo-multiple-name-label"
                      required={true}
                      // error={Boolean(error)}
                    >
                      App Name
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      {...field}
                      value={appName}
                      error={Boolean(error)}
                      onChange={(event: any) => {
                        handleChange(event);
                        field.onChange(event);
                      }}
                      id="demo-multiple-name"
                      multiple
                      className="w-full h-10 rounded-md border-black "
                      MenuProps={MenuProps}
                    >
                      {allAppsData?.data?.result.map((item: any) => (
                        <MenuItem
                          key={item.appId}
                          value={item.appId}
                          style={getStyles(item.name, appName, theme)}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <p className="text-xs text-red-500 absolute">
                      {error?.message ? error?.message : " "}
                    </p>
                  </>
                )}
                name="appName"
                control={control}
                rules={{
                  required: "Please select app",
                  // pattern: {
                  //   value: RegExpressions?.emailornumber,
                  //   message: "Please enter valid email or mobile number ",
                  // },
                }}
              />
            </div>
            <div>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <SelectInput
                    {...field}
                    value={gender}
                    required={true}
                    label={"Gender"}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    onChange={(e: any) => {
                      setGender(e.target.value);
                      field.onChange(e.target.value);
                    }}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </SelectInput>
                )}
                name="gender"
                control={control}
                rules={{
                  required: "Please select gender",
                  // pattern: {
                  //   value: RegExpressions?.emailornumber,
                  //   message: "Please enter valid email or mobile number ",
                  // },
                }}
              />
            </div>
            <div>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <SelectInput
                    {...field}
                    value={status}
                    required={true}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    onChange={(e: any) => {
                      setStatus(e.target.value);
                      field.onChange(e.target.value);
                    }}
                    label={"Status"}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </SelectInput>
                )}
                name="status"
                control={control}
                rules={{
                  required: "Please select status",
                  // pattern: {
                  //   value: RegExpressions?.emailornumber,
                  //   message: "Please enter valid email or mobile number ",
                  // },
                }}
              />
            </div>
          </div>
        </CustomModal>
      </form>
    </>
  );
};

export default Users;
