import { useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
} from "@material-tailwind/react";
import CustomModal from "../../common/Modal";
import { useState } from "react";
import CustomCard from "../../common/Card";
import { useForm, Controller } from "react-hook-form";
import CustomInput from "../../common/Input";
import {
  useCreateAppMutation,
  useGetAllAppsQuery,
  useUpdateAppMutation,
} from "../../../redux/services/appApiSlice";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { useDispatch } from "react-redux";
import { RegExpressions } from "../../../constants/enums";

const Applications = () => {
  const [addApplication, addApplicationData] = useCreateAppMutation();
  const [editApplication, editApplicationData] = useUpdateAppMutation();
  const [allApps, setAllApps] = useState<any>([]);
  const { data, isSuccess, isError, refetch, error } = useGetAllAppsQuery({});
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      appName: "",
      appUrl: "",
      callbackUrl: "",
      secretKey: "",
    },
  });
  const dispatch = useDispatch();
  const [addAppCheck, setAddAppCheck] = useState<boolean>(false);
  const [editAppCheck, setEditAppCheck] = useState(false);
  const [selectedApp, setSelectedApp] = useState<any>();

  const handleClose = () => {
    reset();
    if (addAppCheck) {
      setAddAppCheck(false);
    } else {
      setEditAppCheck(false);
    }
  };

  const onSubmit = (data: any) => {
    let requestBody = {
      name: data?.appName,
      url: data?.appUrl,
      callbackUrl: data?.callbackUrl,
      secretKey: data?.secretKey,
    };
    if (editAppCheck) {
      editApplication({ ...requestBody, appId: selectedApp.appId });
    } else {
      addApplication(requestBody);
    }
  };

  useEffect(() => {
    if (addApplicationData?.isSuccess) {
      refetch();
      setAddAppCheck(false);
      reset();
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Application added successfully",
        })
      );
    }
  }, [addApplicationData?.isSuccess]);

  useEffect(() => {
    if (isSuccess && data && data?.result?.length) {
      setAllApps(data?.result);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "No Applications Found",
        })
      );
    }
  }, [isError, error]);

  // useEffect(() => {
  //   if (isError) {
  //     <Alert color="red">Application added successfully</Alert>;

  //     // alert("No Application Data Found");
  //   }
  // }, [isError]);

  useEffect(() => {
    if (addApplicationData?.isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: addApplicationData?.error?.data?.error
            ? addApplicationData?.error?.data?.error?.message
            : addApplicationData?.error?.data?.errors
            ? addApplicationData?.error?.data?.errors[0]?.msg
            : "Something went wrong",
        })
      );
    }
  }, [addApplicationData?.isError]);
  useEffect(() => {
    if (editAppCheck) {
      setValue("appName", selectedApp?.name);
      setValue("appUrl", selectedApp?.url);
      setValue("callbackUrl", selectedApp?.callbackUrl);
      setValue("secretKey", selectedApp?.secretKey);
    }
  }, [editAppCheck]);
  useEffect(() => {
    if (editApplicationData?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Application updated successfully",
        })
      );
      setEditAppCheck(false);
      reset();
      refetch();
    }
  }, [editApplicationData?.isSuccess]);
  useEffect(() => {
    if (editApplicationData?.isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: editApplicationData?.error?.data?.error
            ? editApplicationData?.error?.data?.error?.message
            : editApplicationData?.error?.data?.errors
            ? editApplicationData?.error?.data?.errors[0]?.msg
            : "Something went wrong",
        })
      );
    }
  }, [editApplicationData?.isError]);

  return (
    <>
      <Card className="h-full w-full" placeholder={undefined}>
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none sticky top-[72px] z-[80] mx-0 m-0 "
          placeholder={undefined}
        >
          <div className="mb-2 flex items-center justify-between gap-8 p-4 ">
            <div>
              <Typography
                variant="h5"
                color="blue-gray"
                placeholder={undefined}
              >
                Applications
              </Typography>
              <Typography
                color="gray"
                className="mt-1 font-normal"
                placeholder={undefined}
              >
                See information about all applications
              </Typography>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              {/* <Button variant="outlined" size="sm" placeholder={undefined}>
                view all
              </Button> */}
              <Button
                className="flex items-center gap-3"
                size="sm"
                placeholder={undefined}
                onClick={() => setAddAppCheck(true)}
              >
                <PlusCircleIcon strokeWidth={2} className="h-4 w-4" /> Add
                Application
              </Button>
            </div>
          </div>
          <hr />

          {/* <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <div className="w-full ">
              <Input
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                crossOrigin={undefined}
              />
            </div>
          </div> */}
        </CardHeader>
        <CardBody
          className=" no-scrollbar overflow-scroll grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
          placeholder={undefined}
        >
          {allApps?.map((appData: any, index: any) => {
            return (
              <CustomCard
                data={appData}
                edit={editAppCheck}
                setEdit={setEditAppCheck}
                setSelectedApp={setSelectedApp}
              ></CustomCard>
            );
          })}
        </CardBody>
      </Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomModal
          buttonType="submit"
          onClose={handleClose}
          saveText={editAppCheck ? "Save Changes" : "Save"}
          showModal={addAppCheck || editAppCheck}
          title={"Add Application"}
          className={"expandAnimationBottom"}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <CustomInput
                    label="Application Name"
                    {...field}
                    disabled={editAppCheck ? true : false}
                    value={field.value}
                    placeholder="Enter application name"
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    //   inputRef={(e: any) => {
                    //     ref(e);
                    //     inputRef.current = e;
                    //   }}
                    required={true}
                    // autoFocus={true}
                  />
                )}
                name="appName"
                control={control}
                rules={{
                  required: "Please enter application name",
                  pattern: {
                    value: RegExpressions?.FirstName,
                    message: "Please enter valid name ",
                  },
                }}
              />
            </div>

            <div>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <CustomInput
                    label="Application URL"
                    {...field}
                    value={field.value}
                    disabled={editAppCheck ? true : false}
                    placeholder="Application URL"
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    //   inputRef={(e: any) => {
                    //     ref(e);
                    //     inputRef.current = e;
                    //   }}
                    required={true}
                    // autoFocus={true}
                  />
                )}
                name="appUrl"
                control={control}
                rules={{
                  required: "Please enter application url",
                  pattern: {
                    value: RegExpressions?.Url,
                    message: "Please enter valid url ",
                  },
                }}
              />
            </div>
            <div>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <CustomInput
                    label="Callback URL"
                    {...field}
                    value={field.value}
                    placeholder="Callback URL"
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    //   inputRef={(e: any) => {
                    //     ref(e);
                    //     inputRef.current = e;
                    //   }}
                    required={true}
                    // autoFocus={true}
                  />
                )}
                name="callbackUrl"
                control={control}
                rules={{
                  required: "Please enter callback url",
                  pattern: {
                    value: RegExpressions?.CallbackUrl,
                    message: "Please enter valid callback url",
                  },
                }}
              />
            </div>
            <div>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <CustomInput
                    label="Secret Key"
                    {...field}
                    value={field.value}
                    disabled={editAppCheck ? true : false}
                    placeholder="Secret Key"
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    //   inputRef={(e: any) => {
                    //     ref(e);
                    //     inputRef.current = e;
                    //   }}
                    required={true}
                    // autoFocus={true}
                  />
                )}
                name="secretKey"
                control={control}
                rules={{
                  required: "Please enter secret key",
                  // pattern: {
                  //   value: RegExpressions?.emailornumber,
                  //   message: "Please enter valid email or mobile number ",
                  // },
                }}
              />
            </div>
          </div>
        </CustomModal>
      </form>
    </>
  );
};

export default Applications;
