import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface NavBarItemProps {
  label: string;
  active?: boolean;
  href: string;
  onClick?: () => void;
}
const NavBarItem: React.FC<NavBarItemProps> = ({
  label,
  active,
  href,
  onClick,
}) => {
  return (
    <Link
      onClick={onClick}
      to={href}
      className={twMerge(
        `
        block mt-4 sm:inline-block sm:mt-0 text-gray-300 hover:text-white mr-4
        `,
        active && `text-cyan-500`
      )}
    >
      <p className="truncate w-full">{label}</p>
    </Link>
  );
};

export default NavBarItem;
