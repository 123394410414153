import { Controller, useForm } from "react-hook-form";
import Box from "../../common/Box";
import { MessageAlert } from "../../common/MessageAlert";
import CustomInput from "../../common/Input";
import { RegExpressions } from "../../../constants/enums";
import { logo } from "../../../assets/icons";
import { useForgetPasswordMutation } from "../../../redux/services/authApiSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";

export const ForgetPassword = () => {
  const { handleSubmit, control } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      email: null,
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgetPassword, forgetPasswordInfo] = useForgetPasswordMutation();
  const onSubmit = (data: any) => {
    forgetPassword(data);
  };
  useEffect(() => {
    if (forgetPasswordInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: forgetPasswordInfo?.data?.message,
        })
      );
      navigate(Page.LOGIN);
    }
  }, [forgetPasswordInfo?.isSuccess]);
  useEffect(() => {
    if (forgetPasswordInfo?.isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: forgetPasswordInfo?.error?.data?.error?.message,
        })
      );
    }
  }, [forgetPasswordInfo?.isError]);
  const onSignInClick = () => {
    navigate(Page.LOGIN);
  };
  return (
    <>
      <MessageAlert />
      <Box
        className="grid grid-cols-2
                    md:grid-cols-3
                    xl:grid-cols-6 px-2 py-6 xl:items-center xl:justify-center divide-x"
      >
        <div className=" col-span-6 ">
          <div className=" text-2xl flex justify-center p-2 ">
            <div className="flex-col w-[30%] justify-center gap-6  ">
              <div className="flex justify-center">
                <img
                  className="object-fit w-14 h-13 py-2 rounded-full"
                  src={logo}
                  alt="Rounded avatar"
                />
              </div>
              <p className="flex justify-center ">Forget Password</p>
              <div className="flex-col justify-center py-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <CustomInput
                        label="Email"
                        {...field}
                        value={field.value}
                        placeholder="Enter email"
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        //   inputRef={(e: any) => {
                        //     ref(e);
                        //     inputRef.current = e;
                        //   }}
                        required={true}
                        // autoFocus={true}
                      />
                    )}
                    name="email"
                    control={control}
                    rules={{
                      required: "Please enter email address",
                      pattern: {
                        value: RegExpressions?.Email,
                        message: "Please enter valid email.",
                      },
                    }}
                  />

                  <div className="pt-2.5">
                    <button
                      type={"submit"}
                      className="py-2 w-full px-3 items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="text-end">
                    <span
                      className="text-sm text-blue-500 cursor-pointer"
                      onClick={onSignInClick}
                    >
                      Sign In
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
