import { Tooltip } from "@mui/material";

import "./style.css";

export const CustomToolTip = (props: any) => {
  const {
    title,
    children,
    placement,
    open,
    onOpen,
    onClose,
    className,
    disableHoverListener,
  } = props;
  return (
    <>
      <Tooltip
        title={title}
        placement={placement}
        arrow
        onOpen={onOpen}
        onClose={onClose}
        className={className}
        open={open}
        disableHoverListener={disableHoverListener}
      >
        <span>{children}</span>
      </Tooltip>
    </>
  );
};
