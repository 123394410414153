import Box from "../../common/Box";
import { logo } from "../../../assets/icons";
import CustomInput from "../../common/Input";
import { Controller, useForm } from "react-hook-form";
import { useLoginMutation } from "../../../redux/services/authApiSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import { useDispatch } from "react-redux";
import { setLogin } from "../../../redux/features/auth/authSlice";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { MessageAlert } from "../../common/MessageAlert";
import { RegExpressions } from "../../../constants/enums";

const Login = () => {
  const { handleSubmit, control } = useForm({
    mode: "onChange" || "onSubmit",

    defaultValues: {
      userName: null,
      password: null,
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, loginData] = useLoginMutation();
  const onSubmit = (data: any) => {
    login(data);
  };

  useEffect(() => {
    if (loginData?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Login Successfully",
        })
      );
      dispatch(setLogin(true));
      navigate(Page.HOME);
    }
  }, [loginData?.isSuccess]);

  useEffect(() => {
    if (loginData?.isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: loginData?.error?.data?.error?.message,
        })
      );
    }
  }, [loginData?.isError, loginData?.error]);
  const onForgetPasswordClick = () => {
    navigate(Page.FORGETPASSWORD);
  };

  return (
    <>
      <MessageAlert />
      <Box
        className="grid grid-cols-2
                md:grid-cols-3
                xl:grid-cols-6 px-2 py-6 xl:items-center xl:justify-center divide-x"
      >
        {/* <div className="col-span-3 flex justify-center">
        <img
          className="object-fit w-full h-full py-2 rounded-full"
          src={loginBanner}
          alt="Rounded avatar"
        />
      </div> */}
        <div className=" col-span-6 ">
          <div className=" text-2xl flex justify-center p-2 ">
            <div className="flex-col w-[30%] justify-center gap-6  ">
              {/* border-2 border-solid border-gray-100 bg-white p-4 py-12 */}
              <div className="flex justify-center">
                <img
                  className="object-fit w-14 h-13 py-2 rounded-full"
                  src={logo}
                  alt="Rounded avatar"
                />
              </div>
              <p className="flex justify-center ">Log in to your Account</p>
              <div className="flex-col justify-center py-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <CustomInput
                        label="Username"
                        {...field}
                        value={field.value}
                        placeholder="Enter email"
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        //   inputRef={(e: any) => {
                        //     ref(e);
                        //     inputRef.current = e;
                        //   }}
                        required={true}
                        // autoFocus={true}
                      />
                    )}
                    name="userName"
                    control={control}
                    rules={{
                      required: "Please enter email address",
                      pattern: {
                        value: RegExpressions?.Email,
                        message: "Please enter valid email.",
                      },
                    }}
                  />
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <CustomInput
                        label="Password"
                        {...field}
                        value={field.value}
                        forgetPassword={true}
                        onForgetPasswordClick={onForgetPasswordClick}
                        placeholder="Enter Password"
                        showEyeIcon={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                      />
                    )}
                    name="password"
                    control={control}
                    rules={{
                      required: "Please enter password",
                      // pattern: {
                      //   value: RegExpressions?.Password,
                      //   message: "Please enter valid password",
                      // },
                    }}
                  />
                  <div className="pt-2.5">
                    <button
                      type={"submit"}
                      className="py-2 w-full px-3 items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Login;
