import Header from "../common/Header";
import { Outlet } from "react-router";
import { MessageAlert } from "../common/MessageAlert";

const UILayout = () => {
  return (
    <>
      <MessageAlert />
      <Header />
      <Outlet />
    </>
  );
};

export default UILayout;
