import { Controller, useForm } from "react-hook-form";
import Box from "../../common/Box";
import { MessageAlert } from "../../common/MessageAlert";
import CustomInput from "../../common/Input";
import { logo } from "../../../assets/icons";
import { RegExpressions } from "../../../constants/enums";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useResetPasswordMutation } from "../../../redux/services/authApiSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { Page } from "../../../routes/config";

export const ResetPassword = () => {
  const { handleSubmit, control, getValues } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      password: null,
      confirmPassword: null,
    },
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locSearch = new URLSearchParams(location.search);
  const [token, setToken] = useState<any>();
  const [resetPassword, resetPasswordInfo] = useResetPasswordMutation();
  useEffect(() => {
    setToken(locSearch.get("token"));
  }, []);
  const onSubmit = (data: any) => {
    const payload = {
      resetToken: token,
      password: data?.password,
    };
    resetPassword(payload);
  };
  useEffect(() => {
    if (resetPasswordInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: resetPasswordInfo?.data?.message,
        })
      );
      navigate(Page.LOGIN);
    }
  }, [resetPasswordInfo?.isSuccess]);
  useEffect(() => {
    if (resetPasswordInfo?.isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: resetPasswordInfo?.error?.data?.error
            ? resetPasswordInfo?.error?.data?.error?.message
            : resetPasswordInfo?.error?.data?.errors
            ? resetPasswordInfo?.error?.data?.errors[0]?.msg
            : "Something went wrong",
        })
      );
    }
  }, [resetPasswordInfo?.isError]);

  return (
    <>
      <MessageAlert />
      <Box
        className="grid grid-cols-2
                        md:grid-cols-3
                        xl:grid-cols-6 px-2 py-6 xl:items-center xl:justify-center divide-x"
      >
        <div className=" col-span-6 ">
          <div className=" text-2xl flex justify-center p-2 ">
            <div className="flex-col w-[30%] justify-center gap-6  ">
              <div className="flex justify-center">
                <img
                  className="object-fit w-14 h-13 py-2 rounded-full"
                  src={logo}
                  alt="Rounded avatar"
                />
              </div>
              <p className="flex justify-center ">Reset Password</p>
              <div className="flex-col justify-center py-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <CustomInput
                        label="New Password"
                        {...field}
                        value={field.value}
                        placeholder="Enter Password"
                        showEyeIcon={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                      />
                    )}
                    name="password"
                    control={control}
                    rules={{
                      required: "Please enter password",
                      pattern: {
                        value: RegExpressions?.Password,
                        message: "Please enter valid password",
                      },
                    }}
                  />
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <CustomInput
                        label="Confirm Password"
                        {...field}
                        value={field.value}
                        placeholder="Enter Password"
                        showEyeIcon={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                      />
                    )}
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter the confirm password",
                      },
                      validate: () => {
                        if (
                          getValues("password") != getValues("confirmPassword")
                        ) {
                          return "Password not Matched";
                        }
                      },
                    }}
                  />

                  <div className="pt-2.5">
                    <button
                      type={"submit"}
                      className="py-2 w-full px-3 items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
