export const ENUM = "enum";
export const RegExpressions = {
  Email: new RegExp(/^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/),
  Password: new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).{8,}$/
  ),
  FirstName: new RegExp(/^[A-Za-z]+$/),
  MiddleName: new RegExp(/^[A-Za-z]+$/),
  EmployeeId: new RegExp(/^EINN\d+$/),
  MobileNumber: new RegExp(/^[6-9]\d{9}$/),
  Url: new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/),
  CallbackUrl: new RegExp(/^[a-zA-Z0-9 ]*$/),
};
