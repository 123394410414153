import { apiSlice } from "./apiSlice";
import { apiRoutes } from "../../config/api";
export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: (params:any) => ({
        url: apiRoutes.GET_ALL_USERS,
        params:params,
        method: "GET",
      }),
    }),
    createUser: builder.mutation({
      query: (params) => ({
        url: apiRoutes.CREATE_USER,
        method: "POST",
        body: { ...params },
      }),
    }),
    updateUser: builder.mutation({
      query: ({ userId, ...data }) => ({
        url: `${apiRoutes.CREATE_USER}/${userId}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ userId, ...data }) => ({
        url: `${apiRoutes.CREATE_USER}/${userId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApiSlice;
