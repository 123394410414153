import {
  PencilSquareIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/features/alert/alertSlice";

const CustomCard = (props: any) => {
  const { data, edit, setEdit, setSelectedApp } = props;

  const dispatch = useDispatch();
  return (
    <div className="relative block  p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="absolute right-2 top-2 cursor-pointer">
        <PencilSquareIcon
          className="h-5 w-5"
          onClick={() => {
            setEdit(true);
            setSelectedApp(data);
          }}
        />
      </div>
      <h5 className="capitalize break-words mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        {data?.name}
      </h5>
      <div className="text-sm font-normal break-words text-gray-600 dark:text-gray-400 flex items-center gap-2">
        {data?.url}{" "}
        <div
          onClick={() => {
            navigator.clipboard.writeText(data?.url);
            dispatch(
              setAlert({
                open: true,
                alertType: "success",
                message: "Copied to clipboard",
              })
            );
          }}
          className="cursor-pointer"
        >
          <DocumentDuplicateIcon strokeWidth={2} className="h-4 w-4" />
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
