import { createSlice } from "@reduxjs/toolkit";
import { AppUSer } from "../../../models/appUser/appUser";
const initialState = {
  isLoggedIn: false,
} as AppUSer;

const authSlice: any = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, { payload }) => {
      state.isLoggedIn = payload;
    },

    logOut: (state) => initialState,
  },
  extraReducers: (builder) => {},
});

export const { setLogin } = authSlice.actions;
export default authSlice.reducer;
export const getIsLoggedIn = (state: any) => state?.auth?.isLoggedIn;
