import { apiSlice } from "./apiSlice";
import { apiRoutes } from "../../config/api";
export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: apiRoutes.LOGIN,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    forgetPassword: builder.mutation({
      query: (data) => ({
        url: apiRoutes.FORGOT_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: apiRoutes.RESETPASSWORD,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
} = authApiSlice;
