import { InputLabel, Select } from "@mui/material";

const SelectInput = (props: any) => {
  const {
    value,
    error,
    onChange,
    children,
    label,
    helperText,
    placeholder,
    required,
  } = props;

  return (
    <>
      <div className="">
        <InputLabel
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          htmlFor="demo-simple-select"
          required={required}
          // error={error}
        >
          {label}
        </InputLabel>
        <div className="relative">
          <Select
            id="demo-simple-select"
            className="h-10 text-black-500 w-full"
            displayEmpty
            error={error}
            inputProps={{ "aria-label": "Without label" }}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          >
            {children}
          </Select>
        </div>
      </div>
      <p className="text-xs text-red-500 absolute">{helperText}</p>
    </>
  );
};
export default SelectInput;
