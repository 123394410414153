import { apiSlice } from "./apiSlice";
import { apiRoutes } from "../../config/api";
export const appApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllApps: builder.query({
      query: () => ({
        url: apiRoutes.GET_ALL_APPLICATIONS,
        method: "GET",
      }),
    }),
    createApp: builder.mutation({
      query: (params) => ({
        url: apiRoutes.CREATE_APPLICATION,
        method: "POST",
        body: { ...params },
      }),
    }),
    updateApp: builder.mutation({
      query: ({ appId, ...data }) => ({
        url: `${apiRoutes.CREATE_APPLICATION}/${appId}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteApp: builder.mutation({
      query: ({appId,...data}) => ({
        url: `${apiRoutes.CREATE_APPLICATION}/${appId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useGetAllAppsQuery, useCreateAppMutation,useUpdateAppMutation,useDeleteAppMutation } =
appApiSlice;
