import styled from "@emotion/styled";

import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "./style.css";

export const StyledDataGrid = styled((props: any) => {
  const { children, ...rest } = props;
  return <DataGrid {...rest}>{children}</DataGrid>;
})`
  ${(props) => props.tableHeight && `height:${props.tableHeight}px !important`}
`;
export const Table = (props: any) => {
  const {
    rows,
    rowCount,
    columns,
    checkboxSelection,
    isPagination,
    pagination,
    onPaginationModelChange,
    tableHeight,
    columnVisibilityObject,
  } = props;
  return (
    <StyledDataGrid
      tableHeight={tableHeight}
      rows={rows}
      className="tb-0"
      columns={columns}
      initialState={{
        pagination: { paginationModel: pagination },
        columns: {
          columnVisibilityModel: columnVisibilityObject,
        },
      }}
      getRowHeight={() => "auto"}
      rowHeight={200}
      // getRowClassName={getRowClassName}
      pagination={isPagination}
      pageSizeOptions={[5, 10, 20, 50]}
      paginationModel={pagination}
      paginationMode="server"
      onPaginationModelChange={onPaginationModelChange}
      checkboxSelection={checkboxSelection}
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      rowCount={rowCount}
      disableRowSelectionOnClick
      components={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No data available to display
          </Stack>
        ),
      }}
    />
  );
};

export default Table;
