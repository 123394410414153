import { Route, Routes } from "react-router-dom";
import { Page } from "./config";
import Login from "../components/pages/auth";
import Dashboard from "../components/pages/dashboard";
import { useSelector } from "react-redux";
import { getIsLoggedIn } from "../redux/features/auth/authSlice";
import { ProtectedRoute } from "./protectedRoute";
import UILayout from "../components/layout";
import Applications from "../components/pages/applications";
import { ForgetPassword } from "../components/pages/auth/forgetPassword";
import { ResetPassword } from "../components/pages/auth/resetPassword";
const UIRoutes = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <Routes>
      {!isLoggedIn && (
        <>
          <Route path={Page.LOGIN} element={<Login />} />
          <Route path={Page.FORGETPASSWORD} element={<ForgetPassword />} />
          <Route path={Page.RESETPASSWORD} element={<ResetPassword />} />
        </>
      )}
      {/* <Route path={Page.LOGIN} element={<Login />} /> */}
      <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
        <Route path={Page.HOME} element={<UILayout />}>
          <Route path={Page.HOME} element={<Dashboard />} />
          <Route path={Page.APPLICATIONS} element={<Applications />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default UIRoutes;
