import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config/api";

import { encrypted } from "../../components/common/utils";

// import {
//   getAuthTokens,
//   setAuthInfo,
//   setAuthTokens,
//   setRefreshedAuthTokens,
//   setSessionExpiredCheck,
// } from "../features/auth/authSlice";
// import jwt_decode from "jwt-decode";
var token: any;

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  // credentials: 'include',
  prepareHeaders: (headers, { getState }: any) => {
    // Get auth tokens from auth state
    const {
      auth: { accessToken, idToken, refreshToken, tokenScope, ipAddress },
    }: any = getState();
    headers.set("secret-key", encrypted("authenticator"));
    headers.set("app-name", "authenticator");
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result: any = await baseQuery(args, api, extraOptions);

  // if (result?.error?.data?.statusCode === 0 && result?.error?.data?.error?.errType === 'BadRequestError') {
  //     // send refresh token to get new access token
  //     const refreshResult:any = await baseQuery({url: apiRoutes.REFRESH_TOKEN}, api, extraOptions)
  //     if (refreshResult?.data) {
  //         const {idToken, accessToken, refreshToken } = refreshResult?.data?.result;
  //         // store the new token
  //         api.dispatch(setAuthTokens({idToken, accessToken, refreshToken }))
  //         // retry the original query with new access token
  //         result = await baseQuery(args, api, extraOptions)
  //     } else {
  //         api.dispatch(logOut())
  //     }
  // }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 1,
});
